import * as React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./index.css";

export default function Home() {
    return (
        <>
            <Helmet>
                <meta name="description" content="Bot, który dba o przejrzystość Twoich serwerów." />
                <meta property="og:title" content="CENZURA" />
                <meta property="og:description" content="Bot, który dba o przejrzystość Twoich serwerów." />
                <meta property="og:image" content="/logo.png" />
                <meta name="theme-color" content="#e74c3c" />
            </Helmet>
            <div className="hero flex h-screen bg-cool-gradient">
                <div className="mx-auto md:ml-12 lg:ml-40 my-auto">
                    <div className="rounded overflow-hidden">
                        <div className="px-6 py-4">
                            <div className="flex items-center justify-left space-x-2">
                                <img src="/logo.png" alt="cenzura logo" width={40} height={40} />
                                <h1 className="font-bold text-4xl xl:text-5xl text-white gradient">CENZURA</h1>
                            </div>
                            <p className="font-bold text-base md:text-2xl text-white my-4">Bot, który dba o <span className="gradient">przejrzystość</span> Twoich serwerów.</p>
                            <div className="space-x-2">
                                <button onClick={() => window.location.href = "https://discord.com/oauth2/authorize?client_id=705552952600952960&permissions=268561494&scope=bot+applications.commands"} className="bg-zinc-800 w-30 md:w-40 hover:bg-pink-800 duration-200 text-white py-4 px-4 text-sm md:text-lg rounded">dodaj bota</button>
                                <button onClick={() => window.location.href = "https://discord.com/oauth2/authorize?client_id=921822086102679572&permissions=268561494&scope=bot+applications.commands"} className="bg-zinc-800 w-30 md:w-40 hover:bg-pink-800 duration-200 text-white py-4 px-4 text-sm md:text-lg rounded">dodaj testy bot</button>
                                <button onClick={() => window.location.href ="https://github.com/PoligonTeam/cenzura"} className="bg-zinc-800 w-30 md:w-40 hover:bg-pink-800 duration-200 text-white py-4 px-4 text-sm md:text-lg rounded">kod bota</button>
                                <Link to="/privacy"><button className="bg-zinc-800 w-30 md:w-40 hover:bg-pink-800 duration-200 text-white py-4 px-4 text-sm md:text-lg rounded">prywatność</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
